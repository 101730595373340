import { Cookies } from "react-cookie-consent"

export const trackHubSpotViews = location => {
  if (!Array.isArray(window._hsq)) {
    return
  }

  const trackPageView = () => {
    const path = location
      ? location.pathname + location.search + location.hash
      : undefined
    window._hsq.push(["setPath", path])
    window._hsq.push(["trackPageView"])
  }

  // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
  // react-helmet is using requestAnimationFrame (https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299)
  if (typeof window.requestAnimationFrame === "function") {
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(trackPageView)
    })
  } else {
    // simulate 2 requestAnimationFrame calls
    setTimeout(trackPageView, 32)
  }
}

export const addHubSpotScript = () => {
  return new Promise((resolve, reject) => {
    if (!Cookies.get("gatsby-grape-hubspot")) return reject()
    if (window.gatsbyGrapeHubSpotScriptAdded) return resolve(true)

    const trackingCode = process.env.GATSBY_HUBSPOT_TRACKING_CODE

    const head = document.getElementsByTagName("head")[0]
    const script = document.createElement(`script`)
    script.type = `text/javascript`
    script.onload = () => {
      window.gatsbyGrapeHubSpotScriptAdded = true
      resolve(true)
    }
    script.src = `//js.hs-scripts.com/${trackingCode}.js`

    head.appendChild(script)
  })
}
