/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "styles/global.scss"
import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { addHubSpotScript, trackHubSpotViews } from "src/utils/hubSpotTracking"

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}>
      {element}
    </GoogleReCaptchaProvider>
  )
}

export const onClientEntry = () => {
  addHubSpotScript()
    .then(() => {
      trackHubSpotViews(window.location)
    })
    .catch(() => {})
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  // only add script if not first page load
  // first page load is handled in onClientEntry
  if (prevLocation) {
    addHubSpotScript()
      .then(() => {
        trackHubSpotViews(location)
      })
      .catch(() => {})
  }
}
