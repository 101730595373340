exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-unsubscribe-jsx": () => import("./../../../src/pages/unsubscribe.jsx" /* webpackChunkName: "component---src-pages-unsubscribe-jsx" */),
  "component---src-templates-case-study-index-jsx": () => import("./../../../src/templates/caseStudy/index.jsx" /* webpackChunkName: "component---src-templates-case-study-index-jsx" */),
  "component---src-templates-page-hr-consent-jsx": () => import("./../../../src/templates/page/HrConsent.jsx" /* webpackChunkName: "component---src-templates-page-hr-consent-jsx" */),
  "component---src-templates-page-index-jsx": () => import("./../../../src/templates/page/index.jsx" /* webpackChunkName: "component---src-templates-page-index-jsx" */),
  "component---src-templates-page-job-consent-jsx": () => import("./../../../src/templates/page/JobConsent.jsx" /* webpackChunkName: "component---src-templates-page-job-consent-jsx" */),
  "component---src-templates-page-newsletter-jsx": () => import("./../../../src/templates/page/Newsletter.jsx" /* webpackChunkName: "component---src-templates-page-newsletter-jsx" */),
  "component---src-templates-service-index-jsx": () => import("./../../../src/templates/service/index.jsx" /* webpackChunkName: "component---src-templates-service-index-jsx" */)
}

